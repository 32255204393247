const $ = require('jquery');

// Global jQuery import.
global.$ = global.jQuery = window.$ = window.jQuery = $;

// Plugin from Polo, imported as Yarn deps.
// (not everything is imported, only the potentially relevant ones)
require('@oimmei/pololight/@popperjs/core');
require('@oimmei/pololight/bootstrap/dist/js/bootstrap');
require('@oimmei/pololight/animate.css/animate.min.css');
require('@oimmei/pololight/bootstrap-switch/dist/js/bootstrap-switch.min');
require('@oimmei/pololight/bootstrap-switch/dist/css/bootstrap3/bootstrap-switch.min.css');
require('@oimmei/pololight/@fortawesome/fontawesome-free/scss/brands.scss');
require('@oimmei/pololight/@fortawesome/fontawesome-free/scss/regular.scss');
require('@oimmei/pololight/@fortawesome/fontawesome-free/scss/solid.scss');
require('@oimmei/pololight/@fortawesome/fontawesome-free/scss/fontawesome.scss');
require('@oimmei/pololight/list.js');
require('@oimmei/pololight/magnific-popup');
require('@oimmei/pololight/infinite-scroll');
require('./notify.min');

// https://www.npmjs.com/package/jquery.easing
require('@oimmei/pololight/jquery.easing');

const jQueryBridget = require('@oimmei/pololight/jquery-bridget');
const Flickity = require('@oimmei/pololight/flickity');
// This has to be required separately:
// https://flickity.metafizzy.co/options#imagesloaded
require('@oimmei/pololight/flickity-imagesloaded');

// make Flickity a jQuery plugin
Flickity.setJQuery($);
jQueryBridget('flickity', Flickity, $);

// Added plugins from old versions.
require('@oimmei/pololight/easy-pie-chart');
require('@oimmei/pololight/ion-rangeslider/css/ion.rangeSlider.css');
require('@oimmei/pololight/jquery-breakpoints');
require('@oimmei/pololight/isotope-layout/dist/isotope.pkgd.min');
require('@oimmei/pololight/owl-carousel-2/owl.carousel.min');
require('@oimmei/pololight/owl-carousel-2/assets/owl.carousel.css');
require('./owl.fix');
require('@oimmei/pololight/fitvids');
require('@oimmei/pololight/vide');
require('@oimmei/pololight/scrolly');
require('@oimmei/pololight/js-cookie');
require('@oimmei/pololight/jquery-form');
require('@oimmei/pololight/scrollify');
require('@oimmei/pololight/jquery-countto');
require('@oimmei/pololight/morphext/dist/morphext.min');
require('@oimmei/pololight/sticky-sidebar');
require('@oimmei/pololight/jquery-lazy');
require('@oimmei/pololight/theia-sticky-sidebar');
require('@oimmei/pololight/waypoints/lib/noframework.waypoints.js');
require('@oimmei/pololight/waypoints/lib/jquery.waypoints.js');

// Local files import.
require('./functions.js');
require('../scss/app.scss');
// require('../css/inspiro.css');
require('../scss/style.scss');

// Initializing everything.
var Cookies = require('@oimmei/pololight/js-cookie');
global.Cookies = Cookies;

$(document).ready(function () {
    console.log('OIMMEI START SCRIPT');
});
